<template>
  <div>
    <app-ctrl-year-month
      v-model="date"
      ref="elCtrlYM"
      @input="getList()"
    ></app-ctrl-year-month>

    <table class="table">
      <tr>
        <th>NOMBRE</th>
        <th>SOBRECARGOS</th>
        <th>MONTO</th>
        <th>TOTAL</th>
        <th></th>
      </tr>

      <tr v-for="(l, i) in list" :key="i">
        <td>{{ l.employee_name }}</td>
        <td>
          <b>
            <app-span-money
              :quantity="l.overload"
              :moneyCode="1"
            ></app-span-money>
          </b>
          <br />
          <template v-for="(l1, i1) in l.overloads">
            <span :key="`${i1}_1`">{{ l1.concept }} </span>
            <app-span-money
              :key="`${i1}_2`"
              :quantity="l1.amount"
              :moneyCode="1"
            ></app-span-money>
            <br :key="`${i1}_3`" />
          </template>
        </td>
        <td>
          <app-span-money
            :quantity="l.liquidation.amount"
            :moneyCode="1"
          ></app-span-money>
        </td>
        <td>
          <app-span-money
            :quantity="l.liquidation.amount + l.overload"
            :moneyCode="1"
          ></app-span-money>
        </td>
        <td></td>
      </tr>
    </table>
  </div>
</template>
<script>
import { AdminService } from "../AdminService";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    date: {},
    list: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    async getList() {
      this.list = await AdminService.getReportEmployeeLiquidations({
        ...this.$refs.elCtrlYM.getValue()
      });
    }
  }
};
</script>

<style></style>
